import { SnowplowWrapper } from './SnowPlowWrapper';
import { checkPerformanceCookiesEnabled } from 'core/utils/cookies';

const SNOWPLOW_TRACKER_NAME_EDITOR = 'webTracker';

const CookieHandler = {
  areSnowplowCookiesEnabled() {
    return checkPerformanceCookiesEnabled();
  },
};

const isProd = process.env.GATSBY_ENVIROMENT == 'production' ? true : false;

export const snowplowWrapper = new SnowplowWrapper(
  SNOWPLOW_TRACKER_NAME_EDITOR,
  isProd,
  CookieHandler
);
