import '@genially/public-web-components/dist/index.css';

import LogRocket from 'logrocket';
import { checkPerformanceCookiesEnabled } from 'core/utils/cookies';
import { snowplowWrapper } from 'core/services/snowplow';

const onClientEntry = () => {
  const sendPageView = () => {
    // Snowplow tracking for all pages and all cases
    snowplowWrapper.initTracker();
    snowplowWrapper.sendPageViewEvent();
  };
  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(sendPageView, 32);
};

const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    const cookiesEnabled = checkPerformanceCookiesEnabled();
    if (cookiesEnabled) {
      LogRocket.init('genially-z2e5p/genially');
    }
  }

  async function checkConditions() {
    const response = await window.fetch('https://auth.genially.com/logged', {
      method: 'GET',
      credentials: 'include',
    });
    const isLogged = await response.json();

    if (isMobile.any() && isLogged.logged) {
      console.log('logrocket: mobile-logged');
    }

    if (isMobile.any() && !isLogged.logged) {
      console.log('logrocket: mobile-no-logged');
    }

    if (!isMobile.any() && isLogged.logged) {
      console.log('logrocket: desktop-logged');
    }

    if (!isMobile.any() && !isLogged.logged) {
      console.log('logrocket: desktop-no-logged');
    }
  }
  const cookiesEnabled = checkPerformanceCookiesEnabled();
  if (cookiesEnabled) {
    checkConditions();
  }
};

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export { onClientEntry, onInitialClientRender };
