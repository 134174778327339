import Cookies from 'js-cookie';

export const checkPerformanceCookiesEnabled = () => {
  let performanceAccepted = false;
  const getCookies = Cookies.get('OptanonConsent');
  if (getCookies === undefined) return performanceAccepted;
  const OptanonCookies = getCookies.split('&');
  const result = OptanonCookies.filter((el: any) => el.startsWith('groups'));
  if (result.length > 0) {
    const groups = result[0].split(',');
    if (Number(groups[1].split(':')[1]) > 0) {
      performanceAccepted = true;
    }
  }
  return performanceAccepted;
};
