// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-views-create-create-tsx": () => import("./../../../src/views/Create/Create.tsx" /* webpackChunkName: "component---src-views-create-create-tsx" */),
  "component---src-views-home-home-tsx": () => import("./../../../src/views/Home/Home.tsx" /* webpackChunkName: "component---src-views-home-home-tsx" */),
  "component---src-views-landing-landing-tsx": () => import("./../../../src/views/Landing/Landing.tsx" /* webpackChunkName: "component---src-views-landing-landing-tsx" */),
  "component---src-views-megamenu-megamenu-tsx": () => import("./../../../src/views/Megamenu/Megamenu.tsx" /* webpackChunkName: "component---src-views-megamenu-megamenu-tsx" */),
  "component---src-views-template-category-template-category-tsx": () => import("./../../../src/views/TemplateCategory/TemplateCategory.tsx" /* webpackChunkName: "component---src-views-template-category-template-category-tsx" */),
  "component---src-views-template-template-tsx": () => import("./../../../src/views/Template/Template.tsx" /* webpackChunkName: "component---src-views-template-template-tsx" */),
  "component---src-views-webinar-webinar-tsx": () => import("./../../../src/views/Webinar/Webinar.tsx" /* webpackChunkName: "component---src-views-webinar-webinar-tsx" */)
}

