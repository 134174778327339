import {
  SNOWPLOW_APP_ID,
  SNOWPLOW_MINI_COLLECTOR,
  SNOWPLOW_PRODUCTION_COLLECTOR,
} from './constants';
import {
  clearUserData,
  disableAnonymousTracking,
  enableAnonymousTracking,
  newTracker,
  trackPageView,
} from '@snowplow/browser-tracker';

import { VWOPlugin } from './VWOPlugin';

export interface SnowplowCookieHandler {
  areSnowplowCookiesEnabled(): boolean | Error;
}

export class SnowplowWrapper {
  private readonly cookieHandler: SnowplowCookieHandler;
  private readonly collectorUrl: string = SNOWPLOW_MINI_COLLECTOR;
  private readonly trackerName: string;

  public constructor(
    trackerName: string,
    isProduction: boolean,
    cookieHandler: SnowplowCookieHandler
  ) {
    if (isProduction) {
      this.collectorUrl = SNOWPLOW_PRODUCTION_COLLECTOR;
    }
    this.trackerName = trackerName;
    this.cookieHandler = cookieHandler;
  }

  public isProductionCollector = (): boolean => this.collectorUrl === SNOWPLOW_PRODUCTION_COLLECTOR;

  public initTracker = (): void => {
    if (this.cookieHandler.areSnowplowCookiesEnabled()) {
      newTracker(this.trackerName, this.collectorUrl, {
        appId: SNOWPLOW_APP_ID,
        plugins: [VWOPlugin()],
        stateStorageStrategy: 'cookieAndLocalStorage',
        discoverRootDomain: true,
      });
    } else {
      newTracker(this.trackerName, this.collectorUrl, {
        appId: SNOWPLOW_APP_ID,
        plugins: [VWOPlugin()],
        anonymousTracking: { withSessionTracking: true, withServerAnonymisation: true },
        stateStorageStrategy: 'cookieAndLocalStorage',
        discoverRootDomain: true,
      });
    }
  };

  public track = (): void => {
    if (this.cookieHandler.areSnowplowCookiesEnabled()) {
      this.trackWithCookies();
    } else {
      this.trackWithoutCookies();
    }
  };

  private readonly trackWithoutCookies = (): void => {
    enableAnonymousTracking({
      options: { withSessionTracking: true, withServerAnonymisation: true },
      stateStorageStrategy: 'cookieAndLocalStorage',
    });
    clearUserData({ preserveSession: true, preserveUser: true });
  };

  private readonly trackWithCookies = (): void => {
    disableAnonymousTracking({ stateStorageStrategy: 'cookieAndLocalStorage' });
  };

  public sendPageViewEvent = (): void => {
    this.track();
    trackPageView({}, [this.trackerName]);
  };
}
